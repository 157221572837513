import { PropsWithChildren, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Box, BoxProps, Link, Text } from 'theme-ui';
import { List } from './theme/list';
import { ReactComponent as ArrowDownSvg } from 'icons/svg/arrow-down-24.svg';
import { ReactComponent as ArrowUpSvg } from 'icons/svg/arrow-up-24.svg';

type ListItemStyleExtProps = {
  isHref?: boolean;
  href: string;
};

const ListItemStyle = ({
  isHref = true,
  href,
  children,
  ...other
}: PropsWithChildren<ListItemStyleExtProps & BoxProps>) => (
  <LinkItem {...other}>
    {isHref ? (
      <Link variant='primary' href={href}>
        {children}
      </Link>
    ) : (
      children
    )}
  </LinkItem>
);

const LinkItem = styled(Box)<any>(({ theme }) => ({
  display: 'flex',
  // height: 24,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: 4,
  paddingRight: 4,

  color: theme.colors.text,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
  },
  ':hover': { cursor: 'pointer' },
}));

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
    icon?: JSX.Element;
  }[];
};

const ItemDisplay = ({ icon, title }: { icon: any; title: any }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', fill: 'highlight' }}>
    {icon && icon}
    <Text>{title && title}</Text>
  </Box>
);

function NavItem({ item }: { item: NavItemProps }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, icon, children } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;
 
  const [open, setOpen] = useState(isActiveRoot);
  
  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    bg: theme.colors['darker-mask'],
    '&:before': { display: 'block' },
  };

  const activeSubStyle = { bg: theme.colors['lighter-mask'] };

  if (children) {
    return (
      <>
        <ListItemStyle
          isHref={false}
          href={path}
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ItemDisplay icon={open ? <ArrowUpSvg /> : <ArrowDownSvg />} title={title} />
        </ListItemStyle>

        <Box
          sx={{
            p: 0,
            m: 0,
            display: open ? 'block' : 'none',
            opacity: open ? 1 : 0,
            transition: 'opacity 1s ease-out',
          }}
        >
          <List sx={{ p: 0 }}>
            {children.map((item, index) => {
              const { title, path, icon } = item;
              const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

              return (
                <ListItemStyle
                  key={index}
                  href={path}
                  sx={{
                    ml: 3,
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ItemDisplay icon={icon} title={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Box>
      </>
    );
  }

  return (
    <ListItemStyle
      href={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ItemDisplay icon={icon} title={title} />
    </ListItemStyle>
  );
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
  navConfig: {
    subheader: string;
    items: any;
  }[];
}

export default function NavSection({ navConfig, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((list, index) => {
        const { items } = list;
        return (
          <List key={index}>
            {items.map((item: NavItemProps, index: number) => (
              <NavItem key={index} item={item} />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
