import { ThemeProvider, ThemeUIStyleObject } from 'theme-ui';

const autofillStyles: ThemeUIStyleObject = {
  boxShadow: 'inset 0 0 0 1000px var(--theme-ui-colors-blue)',
  fontSize: 1,
  color: 'deco',
  ':first-line': {
    fontSize: 1,
  },
};

export const theme = {
  colors: {
    primary: '#FF1493',
    secondary: '#FF69B4',
    'prim-trans': '#FF149333',
    light: '#FFb7b9',
    text: '#FFFFE0',
    'text-trans': '#FFFFE066',
    deco: '#f6f633',
    background: '#230861',
    bg: '#230861',
    bgfade: '#331876',
    bg2: '#4d2d81',
    muted: '#f6f679',
    link: '#fe34a2',
    gray: '#8d8daf',
    gray2: '#584d7f',
    highlight: '#fabba8',
    yellow: '#eabb48',
    green: '#63bc8f',
    contrast: '#006400',
    bias: '#326450',
    accent: '#22443a',
    pink: '#d023a1',
    blue: '#63acbf',
    'blue-trans': '#63acbfaa',
    'darker-mask': '#236c9f19',
    'lighter-mask': '#FFFFE022',
  },
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  breakpoints: ['25em', '40em', '64em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    avatar: 32,
  },
  radii: {
    default: 4,
    circle: 99999,
  },
  shadows: {
    card: '0 0 4px rgba(0, 0, 0, .125)',
  },
  text: {
    default: {
      color: 'text',
      fontSize: 1,
      fontWeight: 'body',
    },
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: 'heading',
      color: 'secondary',
      my: '30px',
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      color: 'text',
      fontSize: 1,
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
  },
  variants: {
    avatar: {
      width: 'avatar',
      height: 'avatar',
      borderRadius: 'circle',
    },
    card: {
      p: 2,
      bg: 'darkblue',
      opacity: 0.8,
      boxShadow: 'card',
      borderRadius: '5px',
    },
    link: {
      color: 'primary',
    },
    nav: {
      fontSize: 1,
      fontWeight: 'bold',
      display: 'inline-block',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary',
      },
    },
  },
  buttons: {
    primary: {
      py: 0,
      px: 2,
      minWidth: 60,
      fontSize: 1,
      fontWeight: 'bold',
      color: 'text',
      bg: 'primary',
      borderRadius: '4px',
      ':focus': {
        outline: '1px solid',
        outlineColor: 'deco',
        outlineOffset: '-1px',
      },
      ':hover': {
        cursor: 'pointer',
        bg: 'secondary',
      },
      opacity: 0.8,
      '&:disabled': {
        bg: 'gray',
        cursor: 'default',
      },
      '&:active': {},
    },
    outline: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'transparent',
      border: '1px solid',
      borderColor: 'primary',
      opacity: 0.8,
      ':focus': {
        outline: '1px solid',
        outlineColor: 'deco',
        outlineOffset: '-1px',
      },
      ':hover': {
        cursor: 'pointer',
        bg: 'prim-trans',
        '&:disabled': {
          bg: '#ffffff00',
        },
      },
      '&:disabled': {
        borderColor: 'gray',
        color: 'gray',
        bg: 'dissolve',
        cursor: 'default',
      },
      '&:active': {},
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'background',
      bg: 'secondary',
      ':focus': {
        outline: '1px solid',
        outlineColor: 'deco',
        outlineOffset: '-1px',
      },
      ':hover': {
        cursor: 'pointer',
        bg: 'prim-trans',
      },
      opacity: 0.8,
      '&:disabled': {
        bg: 'gray',
        cursor: 'default',
      },
      '&:active': {},
    },
    dissolve: {
      py: 0,
      px: 2,
      minWidth: 60,
      fontSize: 1,
      fontWeight: 'bold',
      color: 'text',
      bg: 'darker-mask',
      borderRadius: '4px',
      ':focus': {
        outline: '1px solid',
        outlineColor: 'deco',
        outlineOffset: '-1px',
      },
      ':hover': {
        cursor: 'pointer',
        bg: 'text-trans',
      },
      opacity: 0.8,
      '&:disabled': {
        bg: 'gray',
        cursor: 'default',
      },
      '&:active': {},
    },
    blue: {
      py: 0,
      px: 2,
      minWidth: 60,
      fontSize: 1,
      fontWeight: 'bold',
      color: 'text',
      bg: 'blue',
      borderRadius: '4px',
      ':focus': {
        outline: '1px solid',
        outlineColor: 'deco',
        outlineOffset: '-1px',
      },
      ':hover': {
        cursor: 'pointer',
        bg: 'secondary',
      },
      opacity: 0.8,
      '&:disabled': {
        bg: 'gray',
        cursor: 'default',
      },
      '&:active': {},
    },
  },
  forms: {
    primary: {
      display: 'block',
      width: '100%',
      py: 0,
      px: 2,
      appearance: 'none',
      fontSize: 1,
      lineHeight: 'inherit',
      border: '1px solid',
      borderRadius: 4,
      color: 'text',
      bg: 'transparent',
      ':focus': {
        outline: '1px solid',
        outlineColor: 'deco',
        outlineOffset: '-1px',
      },
      '::placeholder': {
        color: 'text-trans',
      },
      ':autofill, :autofill:hover, :autofill:focus': autofillStyles,
      ':-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus': autofillStyles,
    },
    select: {
      display: 'block',
      width: '100%',
      py: 0,
      px: 2,
      appearance: 'none',
      fontSize: 1,
      lineHeight: 'inherit',
      border: '1px solid',
      borderRadius: 4,
      color: 'text',
      bg: 'transparent',
      ':focus': {
        outline: '1px solid',
        outlineColor: 'deco',
        outlineOffset: '-1px',
      },
      '::placeholder': {
        color: 'text-trans',
      },
      ':autofill, :autofill:hover, :autofill:focus': autofillStyles,
      ':-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus': autofillStyles,
    },
    label: {
      fontSize: 1,
      fontWeight: 'bold',
    }
  },
  links: {
    primary: {
      ':visited': {
        color: 'pink',
      },
      ':link': {
        color: 'link',
        textDecoration: 'none',
        fontWeight: 'body',
      },
      ':hover': {
        color: 'primary',
      },
    },
    bold: {
      fontWeight: 'bold',
    },
    nav: {
      fontWeight: 'bold',
      color: 'primary',
      textDecoration: 'none',
    },
  },
  lists: {
    primary: {
      li: {
        lineHeight: '24px',
        background: 'url("squirrel.svg") no-repeat left center',
        paddingLeft: '16px',
        listStyleType: 'none',
      },
      fontWeight: 'body',
      bg: 'background',
      display: 'block',
      textAlign: 'start',
      color: 'muted',
      px: 2,
    },
    secondary: {
      li: {
        lineHeight: '24px',
        background: 'url("webhook.svg") no-repeat left center',
        paddingLeft: '16px',
        listStyleType: 'none',
      },
      fontWeight: 'body',
      bg: 'background',
      display: 'block',
      textAlign: 'start',
      color: 'muted',
      px: 3,
    },
    nav: {
      fontWeight: 'bold',
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  badges: {
    primary: {
      color: 'background',
      bg: 'yellow',
      borderRadius: '8px',
      px: 2
    },
    outline: {
      color: 'background',
      bg: 'green',
      borderRadius: '8px',
      px: 2
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      background: 'background',
    },
    hr: {
      color: 'bg2',
    },
  },
};

export default function ThemeConfig({ children }: any) {
  //@ts-ignore
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
