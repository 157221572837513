import React from 'react';
import LoginForm from 'components/authentication/LoginForm';
// import { Grid } from '@mui/material';
import { Box } from 'theme-ui';
function Login() {
  return (
    <Box>
      <Box>
        <LoginForm />
      </Box>
    </Box>
  );
}

export default Login;
