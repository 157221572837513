import { Box, Text } from 'theme-ui';

import NavSection from 'components/NavSection';
import sidebarConfig from './SidebarConfig';

const DRAWER_WIDTH = 140;

export default function DashboardSidebar() {
  const renderContent = <NavSection navConfig={sidebarConfig} />;

  return (
    <Box sx={{ width: DRAWER_WIDTH, minWidth: DRAWER_WIDTH, borderRight: '1px dotted', borderRightColor: 'lighter-mask' }}>
      <Box sx={{ m: 1, ml: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Text color='primary'>Jojo Admin</Text>
      </Box>
      <Box sx={{ mt: 2, width: DRAWER_WIDTH - 1, bg: 'bg' }}>{renderContent}</Box>
    </Box>
  );
}
