// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import { ReactComponent as DashboardSvg } from 'icons/svg/dashboard-24.svg';
import { ReactComponent as MailSvg } from 'icons/svg/mail-16.svg';
import { ReactComponent as ArchiveSvg } from 'icons/svg/archive-16.svg';
import { ReactComponent as HubotSvg } from 'icons/svg/hubot-16.svg';

const sidebarConfig = [
  {
    subheader: 'general',
    items: [
      {
        title: '工作台',
        path: PATH_DASHBOARD.general.dashboard,
        icon: <DashboardSvg />,
      },
      {
        title: '会员管理',
        path: PATH_DASHBOARD.general.userList,
        icon: <HubotSvg />,
      },
      {
        title: '订单管理',
        path: PATH_DASHBOARD.general.order,
        icon: <DashboardSvg />,
        children: [
          {
            title: '未发货',
            path: PATH_DASHBOARD.general.orderPaid,
            icon: <MailSvg />
          },
          {
            title: '所有订单',
            path: PATH_DASHBOARD.general.orderAll,
            icon: <ArchiveSvg />,
          },
        ],
      },
    ],
  },
];

export default sidebarConfig;
