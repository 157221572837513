import styled from '@emotion/styled';
import { Box } from 'theme-ui';
import AccountPopover from './AccountPopover';


const APPBAR_MOBILE = '40px';

const RootStyle = styled(Box)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  height: '40px',
  width: '100%',
}));

const ToolbarStyle = styled(Box)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  paddingLeft: '8px',
  paddingRight: '8px',
  borderBottom: '1px solid',
  borderBottomColor: theme.colors['darker-mask']
}));

export default function DashboardNavbar() {

  return (
    <RootStyle>
      <ToolbarStyle>
        <Box  >
          <AccountPopover/>
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
