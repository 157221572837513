import Router from './routes';
import LoadingScreen from './components/LoadingScreen';
import useAuth from './hooks/useAuth';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();

  return   isInitialized ? <Router/> : <LoadingScreen/>
}
