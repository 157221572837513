function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS = '';

// ----------------------------------------------------------------------


export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS,
  general: {
    dashboard: path(ROOTS, '/dashboard'),
    order: path(ROOTS, '/order'),
    orderPaid: path(ROOTS, '/order/paid'),
    orderAll: path(ROOTS, '/order/all'),
    userList: path(ROOTS, '/user'),
  },
};
