import { lazy, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import DashboardLayout from '../layouts/dashboard';

const Loadable = (Component: React.ElementType) => (props: any) => {
  return (
    <Suspense fallback={<div>Loading ....</div>}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to='/dashboard' replace /> },
        { path: 'dashboard', element: <Dashboard /> },
        {
          path: 'order',
          children: [
            { path: 'paid', element: <OrderPaid/> },
            { path: 'all', element: <OrderAll/> },
            { path: 'detail/:id', element: <OrderDetail/> },
          ],
        },
        { path: 'user', element: <UserList /> },
      ],
    },
    {
      path: '*',
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard')));
const OrderAll = Loadable(lazy(() => import('../pages/order/all')));
const OrderPaid = Loadable(lazy(() => import('../pages/order/paid')));
const OrderDetail = Loadable(lazy(() => import('../pages/order/detail')));
const UserList = Loadable(lazy(() => import('../pages/user')));
