import React from 'react'

import { Box as _Box, BoxOwnProps, BoxProps } from '@theme-ui/components'
import type { Assign, ForwardRef } from '@theme-ui/components/dist/declarations/src/types'
import { __internalProps } from './util'

const Box = _Box as React.ForwardRefExoticComponent<
  BoxProps & React.RefAttributes<HTMLButtonElement>
>

export interface ListProps
  extends Assign<React.ComponentPropsWithRef<'ul'>, BoxOwnProps> {}
/**
 * Primitive button component with variants
 * @see https://theme-ui.com/components/button
 */
export const List: ForwardRef<HTMLButtonElement, ListProps> =
  React.forwardRef(function Button(props, ref) {
    return (
      <Box
        ref={ref as React.Ref<HTMLButtonElement>}
        as="ul"
        variant="primary"
        {...(props as BoxProps)}
        {...__internalProps({
          __themeKey: 'lists',
          __css: {
            appearance: 'none',
            display: props.hidden ? undefined : 'block',
            lineHeight: 'inherit',
            textDecoration: 'none',
            fontSize: 1,
            px: 2,
            py: 2,
            color: 'text',
            bg: 'primary',
            border: 0,
            borderRadius: 4,
            fontWeight: 'body',
          },
        })}
      />
    )
  })