import * as Yup from 'yup';

import { Form, FormikProvider, useFormik } from 'formik';
import { ApiError, LoginService as S } from 'api';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useAuth from 'hooks/useAuth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Box, Button, Input, Text } from 'theme-ui';

type InitialValues = {
  username: string;
  password: string;
  afterSubmit?: string;
};
export default function LoginForm() {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const notify = () => {
    toast('登录成功 !');
  };

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('需要用户名'),
    password: Yup.string().required('需要密码'),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const data = await S.login({ requestBody: { username: values.username, password: values.password } });
        let token = data.access_token!;
        localStorage.setItem('accessToken', token!);

        login(token);

        notify();
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        toast.error((error as ApiError).message);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: (error as any).message });
        }
      }
    },
  });

  const { handleSubmit, getFieldProps } = formik;

  return (
    <Box sx={{ p: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
            <Text color='deco'>MetaJojo Admin</Text>
            </Box>
            <Input
              sx={{ mb: 1 }} 
              variant='primary' 
              placeholder='请输入用户名' 
              {...getFieldProps('username')} 
            />
            <Input
              sx={{ mb: 1 }}
              variant='primary'
              placeholder='请输入密码'
              type='password'
              {...getFieldProps('password')}
            />
            {/* <Input variant='primary' type='username' {...getFieldProps('username')} /> */}
            {/* <Input autoComplete='current-password' type='password' {...getFieldProps('password')} /> */}
            <Button sx={{width: '100%'}} type='submit' variant='primary'>
              登录
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}
