/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdministratorDisplay } from '../models/AdministratorDisplay';
import type { AdministratorPage } from '../models/AdministratorPage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdministratorService {

    /**
     * @returns AdministratorDisplay
     * @throws ApiError
     */
    public static current(): CancelablePromise<AdministratorDisplay> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/administrators/current',
        });
    }

    /**
     * @returns AdministratorPage
     * @throws ApiError
     */
    public static list({
        page,
        pageSize,
        sortBy,
    }: {
        page?: number,
        pageSize?: number,
        sortBy?: string,
    }): CancelablePromise<AdministratorPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/administrators/list',
            query: {
                'page': page,
                'page_size': pageSize,
                'sort_by': sortBy,
            },
        });
    }

}
