import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { Avatar, Box, Button, Divider, Text } from 'theme-ui';
import { usePopperTooltip } from 'react-popper-tooltip';

export default function AccountPopover() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const isMountedRef = useIsMountedRef();

  const [controlledVisible, setControlledVisible] = useState(false);

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    trigger: 'click',
    placement: 'bottom-start',
    closeOnOutsideClick: false,
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
  });

  // const handleOpen = () => {
  //   setControlledVisible(true);
  // };

  const handleClose = () => {
    setControlledVisible(false);
  };

  // const handleSetPassword = async () => {
  //   try {
  //     navigate('/system/set-password');
  //     handleClose();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleLogout = async () => {
    try {
      await logout?.();
      if (isMountedRef.current) {
        navigate('/');
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Avatar
        size={28}
        sx={{':hover': {cursor: 'pointer'}}}
        src='/avatar.jpg'
        ref={setTriggerRef}
        onClick={() => setControlledVisible(!controlledVisible)}
      />
      {visible && (
        <Box bg='accent' color='primary' sx={{borderRadius: 4 }} ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <Box sx={{ px: 1.5, textAlign: 'center' }}>
            <Text>{user?.username}</Text>
          </Box>

          <Divider color='lighter-mask' sx={{ my: 0.5 }} />

          <Box sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
            {/* <Button variant='primary' sx={{mb: 1}} onClick={() => navigate(`/system/account/edit/${user!.id}`)}>
            基本资料
          </Button>
          <Button   variant='primary' sx={{mb: 1}} onClick={handleSetPassword}>
            修改密码
          </Button> */}
            <Button variant='primary' sx={{ mb: 1 }} onClick={handleLogout}>
              退出登录
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
