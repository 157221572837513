/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Order } from '../models/Order';
import type { OrderGoodUserDetail } from '../models/OrderGoodUserDetail';
import type { OrderInfoPage } from '../models/OrderInfoPage';
import type { OrderShipInput } from '../models/OrderShipInput';
import type { OrderStatus } from '../models/OrderStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrderService {

    /**
     * @returns OrderGoodUserDetail
     * @throws ApiError
     */
    public static retrieve({
        id,
    }: {
        /**
         * Orders's unique id
         */
        id: string,
    }): CancelablePromise<OrderGoodUserDetail> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/detail/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns OrderInfoPage
     * @throws ApiError
     */
    public static list({
        page,
        pageSize,
        sortBy,
        status,
    }: {
        page?: number,
        pageSize?: number,
        sortBy?: string,
        status?: OrderStatus,
    }): CancelablePromise<OrderInfoPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders/list',
            query: {
                'page': page,
                'page_size': pageSize,
                'sort_by': sortBy,
                'status': status,
            },
        });
    }

    /**
     * @returns Order
     * @throws ApiError
     */
    public static shipOrder({
        id,
        requestBody,
    }: {
        /**
         * Orders's unique id
         */
        id: string,
        requestBody: OrderShipInput,
    }): CancelablePromise<Order> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/orders/ship/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
