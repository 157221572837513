import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import { Box } from 'theme-ui';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';


const Container = styled(Box)({
  display: 'flex',
  minHeight: '100%',
  height: '100%',
  overflow: 'hidden',
});

const MainFrame = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const MainStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
}));


const Footer = () => (
  <Box sx={{ position: 'sticky', top: '100%', display: 'flex', justifyContent: 'center' }}>Jojo</Box>
);

export default function DashboardLayout() {
  return (
    <Container>
      <DashboardSidebar />
      <MainFrame>
        <DashboardNavbar />
        <MainStyle>
          <Outlet />
          <Footer />
        </MainStyle>
      </MainFrame>
    </Container>
  );
}
